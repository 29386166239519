import React from 'react';
import { Link } from 'gatsby';
import useEmblaCarousel from 'embla-carousel-react';
import Moment from 'react-moment';
import AnchorScroll from 'react-anchor-link-smooth-scroll';
import FeaturedArtist from './featuredArtist';
import StandardVideo from '../media/standardVideo';

const SeriesInfo = ({
  title,
  session,
  where,
  ticketsLink,
  featuredArtists,
  series,
  venueLink,
  setPopupOpen,
  hideTicketingInfo,
  buttonText,
  listingVideo,
  video,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    align: 'start',
    containScroll: true,
  });
  const now = new Date();
  const isPast = session.every((date) => new Date(date) < now);

  return (
    <div className='overflow-hidden'>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 xl:gap-x-24 px-gutter mt-24 lg:mt-64 prose md:prose-lg text-black'>
        {/* Event Info */}
        <div className='mt-8 lg:mt-16 col-start-1 col-end-2 @container'>
          <div className='border-b border-grey w-full'>
            <p className='font-medium'>{series}</p>
            <h3 className='font-medium title-block m-0-important pb-3 lg:pb-16'>
              {title}
            </h3>
          </div>

          <div className='border-b border-grey w-full grid grid-cols-10'>
            <p className='col-span-3 lg:col-span-2'>When:</p>

            <div className='col-start-5 lg:col-start-4 col-span-6'>
              {session?.map((date, i) => {
                return (
                  <p key={i}>
                    <Moment withTitle format='ddd DD MMM YYYY - h:mm A'>
                      {date}
                    </Moment>
                  </p>
                );
              })}
            </div>
          </div>

          <div className='border-b border-grey w-full grid grid-cols-10'>
            <p className='col-span-3 lg:col-span-2'>Where:</p>
            <div className='col-start-5 lg:col-start-4 col-span-5'>
              <p>{where.title}</p>
              {venueLink && (
                <a
                  href={venueLink}
                  target='_blank'
                  rel='noreferrer'
                  className='mb-2 lg:mb-4 -mt-2 lg:-mt-4 block underline-important font-semibold'>
                  Venue Map
                </a>
              )}
            </div>
          </div>

          {ticketsLink && !hideTicketingInfo && (
            <div className='border-b border-grey w-full grid grid-cols-10 items-center'>
              <p className='col-span-3 lg:col-span-2'>Concert info:</p>
              <AnchorScroll
                href='#concert-info'
                className='underline-important font-semibold col-start-5 lg:col-start-4 col-span-5'>
                Read more
              </AnchorScroll>
            </div>
          )}

          {ticketsLink && !hideTicketingInfo && (
            <div className='border-b border-grey w-full grid grid-cols-10 items-center'>
              <p className='col-span-3 lg:col-span-2'>Ticketing:</p>
              <p
                onClick={() => setPopupOpen(true)}
                className='underline-important font-semibold col-start-5 lg:col-start-4 col-span-7 cursor-pointer'>
                Single Concert vs Series Packages
              </p>
            </div>
          )}

          <div className='mt-8 lg:mt-14 flex justify-start gap-x-0 @[460px]:gap-x-3 flex-col @[460px]:flex-row'>
            <div className='inline-flex'>
              {ticketsLink && !isPast ? (
                <></>
              ) : (
                // <a
                //   href={ticketsLink}
                //   target="_blank"
                //   rel="noreferrer"
                //   className="btn-color-white btn-white"
                // >
                //   Book Tickets
                // </a>
                <AnchorScroll
                  href='#concert-info'
                  className='btn-color-white btn-white'>
                  Concert Info
                </AnchorScroll>
              )}
            </div>
            {!isPast && buttonText !== 'Hidden' && (
              <div className='inline-flex'>
                <Link
                  to={
                    ticketsLink ? ticketsLink : '/concert-series-subscription'
                  }
                  target={ticketsLink ? '_blank' : '_self'}
                  rel={ticketsLink ? 'noreferrer' : ''}
                  className='btn-color-black btn-black'>
                  {buttonText}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className='hidden lg:grid lg:grid-cols-2 lg:gap-x-20 gap-y-20 lg:px-gutter lg:-mt-40'
        style={{ direction: 'rtl' }}>
        {featuredArtists?.map((item, i) => (
          <FeaturedArtist
            key={i}
            {...item}
            className={`${i === 1 && 'mt-96'} ${
              i % 2 === 0 && i !== 0 && '-mt-96'
            }`}
          />
        ))}
      </div>

      {/* Mobile Image Carousel */}
      <div ref={emblaRef} className='cursor-none lg:hidden px-gutter'>
        <div className='flex w-10/12 lg:w-1/3 gap-x-6 mt-16'>
          {featuredArtists?.map((item) => (
            <FeaturedArtist {...item} className='flex-shrink-0 w-full' />
          ))}
        </div>
      </div>

      {listingVideo && (
        <div className='px-gutter w-fit mt-20 lg:mt-[10rem] mx-auto'>
          <div className='aspect-w-16 aspect-h-9 w-[90vw] md:w-[60vw]'>
            <StandardVideo
              className='w-full h-full'
              url={video}
              width='100%'
              height='100%'
              muted
              playsinline
              controls
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SeriesInfo;
